/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
    body,
    html,
    #root,
    .main {
        width: 102%;
    }
    #portrait {
        top: -3.6vh;
        transition: 0.5s;
    }
    #show-res {
        display: none;
    }
    .projects div {
        width: 100%
    }
    .sidenav {
        display: flex;
        flex-flow: column;
        align-items: center;
        top: -92%;
        padding: 0;
        width: 100% !important;
    }
    .sidenav a {
        padding: 0;
        width: 100%;
    }
    .social li:first-child {
        padding-left: 30px;
    }
    .open-nav {
        display: none;
    }
    .fa-angle-down {
        top: 100%;
        font-size: 40px;
        position: sticky;
        left: 50%;
        transform: translate(-50%, 0%);
    }
    .fa-angle-up {
        top: 94%;
        font-size: 40px;
        position: sticky;
        left: 50%;
        transform: translate(-50%, 0%);
    }
    .caption span.border {
        font-size: 3em;
        transition: 0.5s;
    }
    .overlay h2 {
        font-size: 2em;
    }
    .overlay {
        background: #f1f1f1;
    }
    footer img {
        width: 60%;
    }
}

@media only screen and (min-width: 600px) {
    .sidenav {
        top: 0 !important;
    }
    .fas {
        display: none !important;
    }
}