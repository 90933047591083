.sidenav {
    height: 100%;
    width: 50px;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: #111;
    opacity: .9;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

.sidenav a {
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    transition: 0.3s;
}

.sidenav a:hover {
    color: #f1f1f1;
    cursor: pointer;
    transform: scale(1.3)
}

.nav-border {
    margin: 0;
    border-top: 1.5px inset;
    width: 100%;
    padding: 12px 30px;
}

.sidenav .open-nav {
    position: absolute;
    top: 0;
    right: 0px;
    margin-left: 50px;
    width: 50px;
    height: 100%;
    background: #111;
    cursor: pointer;
}

.open-nav {
    display: flex;
    flex-direction: row;
}

.open-nav div {
    width: 1px;
    height: 20px;
    background: white;
    margin: 2px;
    top: 48%;
    right: -15px;
    position: relative;
}

#myBtn {
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 50px;
    padding-top: 20px;
    z-index: 99;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    background-color: #111;
    color: white;
    cursor: pointer;
    transition: 0.9s;
}

.nav-line {
    width: 35px;
    height: 5px;
    background-color: black;
    margin: 6px 0;
}

.social {
    display: flex;
    list-style: none;
}

.social li:first-child {
    padding-left: 0;
}

.social li {
    padding: 8px 8px 8px 32px;
}