* {
    margin: 0;
}

body,
html,
#root,
#root > div,
.main {
    height: 100%;
    font: 400 100% "Lato", sans-serif;
    scroll-behavior: smooth;
    width: auto;
    color: #777;
}

h1,
h2,
h3 {
    text-align: center;
}

#bgimg-1,
#bgimg-2,
#bgimg-3,
#bgimg-4,
.resume-page {
    position: relative;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#bgimg-1,
.resume-page {
    background-image: url("../images/img_parallax.jpg");
    min-height: 100%;
}

#bgimg-2 {
    background-image: url("../images/img_parallax2.jpg");
    min-height: 400px;
}

#bgimg-3,
#bgimg-4 {
    background-image: url("../images/img_parallax3.jpg");
    min-height: 300px;
}

.caption {
    position: absolute;
    left: 0;
    top: 35%;
    width: 100%;
    text-align: center;
    color: #000;
}

.caption span.border {
    background-color: white;
    opacity: 0.85;
    color: black;
    font-size: 5em;
    font-weight: bold;
    margin: 0 auto;
    padding: 15px;
    width: max-content;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    mix-blend-mode: screen;
    transition: 0.5s;
}

#portrait {
    height: 200px;
    width: 200px;
    border-radius: 200px;
    position: relative;
    top: -45px;
    transition: 0.5s;
}

.portrait {
    height: min-content;
    width: min-content;
    border-radius: 210px;
    padding: 10px;
    background: black;
    margin-left: auto;
    margin-right: auto;
}

.portrait div {
    height: 200px;
    width: 200px;
    padding: 10px;
    border-radius: 200px;
}

.div-1 {
    color: #777;
    background-color: white;
    text-align: center;
    text-align: justify;
}

.content {
    position: relative;
    background: url("https://vignette.wikia.nocookie.net/codelyoko/images/5/50/Wiki-background/revision/latest?cb=20120207213735");
    padding: 5%;
}

.content h1 {
    font-family: 'heebo-extra-bold';
    font-size: 32px;
    letter-spacing: 2px;
    color: #FFFFFF;
    width: 100%;
    margin-bottom: 4px;
}

.route {
    height: 100%;
}

footer {
    display: flex;
    justify-content: center;
    bottom: 2.5rem;
    position: relative;
    padding-bottom: 5rem;
    width: 100%;
    background: url("https://vignette.wikia.nocookie.net/codelyoko/images/5/50/Wiki-background/revision/latest?cb=20120207213735");
}

footer img {
    height: auto;
    width: 40%;
    margin: 0 auto;
    filter: drop-shadow(1px 1px 1px rgba(255, 255, 255, 0.5))
}