.skills {
    position: relative;
    background: url("https://vignette.wikia.nocookie.net/codelyoko/images/5/50/Wiki-background/revision/latest?cb=20120207213735");
    padding: 5%;
}
.skill{
    display: inline-block;
    color: #FFFFFF;
    border: #4072FF 2px solid;
    margin: 8px 8px;
    border-radius: 20px;
    padding: 8px 18px;
    font-family: 'heebo-bold';
    text-align: center;
}
.skill-title{
    font-family: 'heebo-extra-bold';
    font-size: 32px;
    letter-spacing: 2px;
    color: #FFFFFF;
    margin-bottom: 4px;
}
.skill-name{
    font-size: 20px;
    color: #FFFFFF;
    font-family: 'heebo-regular';
    letter-spacing: 1.2px;
    margin: 54px 0 16px 0;
    padding: 0 15px 0 45px;
}
.skill-name > u{
    text-decoration: none;
}
.skill-container{
    position: relative;
    padding: 0 15px 0 45px;
}
.skill > h4{
    margin: 0px;
    font-size: 16px;
}