.projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: #282E34;
    cursor: pointer;
}

.project-card {
    position: relative;
    width: 50%;
    height: auto;
    text-align: center;
    margin: 5% 0;
}

.project-card a {
    opacity: 1;
    display: block;
    width: 100%;
    transition: .5s ease;
    backface-visibility: hidden;
}

.image,
.overlay {
    height: 100%;
    width: 80%;
}

.overlay {
    display: grid;
    grid-template-columns: auto auto;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.9);
    transition: .5s ease;
    text-align: center;
}

.overlay h2 {
    grid-column: 1 / 3;
    font-size: 4vw;
}

.overlay p {
    grid-column: 1 / 3;
}

.overlay a {
    width: 60%;
    border-radius: 25px;
    margin: 5%;
    padding: 10px;
    background-color: #777;
    justify-self: center;
    align-self: self-end;
    color: white;
    text-decoration: none;
}

.overlay a:hover {
    background-color: #111;
    transform: scale(1.2);
    transition: 0.3s;
}

.overlay:hover {
    opacity: 1;
}