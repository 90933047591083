#close-resume {
    position: fixed;
    top: 20px;
    left: 75px;
    z-index: 99;
}

.resume-page {
    position: relative;
    width: 100%;
    top: 0;
    left: 2%;
    padding: 0 4%;
}

.resume {
    position: relative;
    margin-left: -500px;
    left: 50%;
    width: 1000px;
}

.embed {
    width: 900px;
    height: 1172px;
    padding: 50px;
}